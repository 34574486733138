<template>
  <main id="article" class="article-container">
    <nuxt-lazy-hydrate when-idle>
      <article-section-hero
        :breadcrumb="breadcrumb"
        :title="article.title"
        :description="article.intro"
        :tags="tags"
        :update-date="updatedAt"
        :cover="article.cover"
        show-cover-on-mobile
      />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-article-section-content
        :content="article.content"
        :author="article.author"
        @share-content="handleSharing"
      />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-article-section-tool-bar :tools="toolbar" no-space />
    </nuxt-lazy-hydrate>
    <nuxt-lazy-hydrate when-visible>
      <lazy-article-section-discover-articles
        v-if="similarArticles.length"
        :articles="similarArticles"
        :parent-category-slug="parentCategory.slug"
      />
    </nuxt-lazy-hydrate>
  </main>
</template>

<script>
export default {
  provide() {
    return {
      parentCategory: this.parentCategory,
      childCategory: this.childCategory,
    }
  },
  props: {
    parentCategory: {
      type: Object,
    },
    article: {
      type: Object,
    },
    toolbar: {
      type: Object,
      required: true,
    },
    similarArticles: {
      type: Array,
      required: true,
    },
  },
  computed: {
    breadcrumb() {
      return [
        {
          label: this.parentCategory.title,
          to: `/${this.parentCategory.slug}`,
        },
        {
          label: this.childCategory.title,
          to: `/${this.parentCategory.slug}/${this.childCategory.slug}`,
        },
        {
          label: this.article.title,
          to: `/${this.parentCategory.slug}/${this.article.slug}`,
        },
      ]
    },
    childCategory() {
      return this.article.child_category
    },
    tags() {
      return this.article.tags.map((tag) => tag.name)
    },
    updatedAt() {
      return new Date(this.article.updatedAt)
    },
    sanitizedInto() {
      const div = document.createElement('div')
      div.innerHTML = this.article.intro

      return div.innerText
    },
  },
  methods: {
    handleSharing(network) {
      if ('share-native' === network) {
        if (navigator.share !== undefined) {
          navigator.share({
            title: this.article.title,
            text: this.sanitizedInto,
            url: window.location.href,
          })
        } else {
          navigator.clipboard.writeText(window.location.href)
        }
      } else {
        const networks = {
          facebook:
            'https://www.facebook.com/sharer/sharer.php?u=@u&title=@t&description=@d&quote=@q&hashtag=@h',
          linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=@u',
          twitter: 'https://twitter.com/intent/tweet?text=@t&url=@u&hashtags=@h@tu',
        }

        let link = networks[network]

        if (network === 'twitter') {
          if (!this.tags.length) link = link.replace('&hashtags=@h', '')
        }

        let encodedHashtags = this.tags.join(',')

        if (network === 'facebook' && encodedHashtags.length) {
          encodedHashtags = '%23' + encodedHashtags.split(',')[0]
        }

        link = link
          .replace(/@tu/g, '&via=' + encodeURIComponent('horiz_io')) // TODO - Add to global settings
          .replace(/@u/g, encodeURIComponent(window.location.href))
          .replace(/@t/g, encodeURIComponent(this.article.title))
          .replace(/@d/g, encodeURIComponent(this.sanitizedInto))
          .replace(/@q/g, encodeURIComponent(this.sanitizedInto))
          .replace(/@h/g, encodedHashtags)

        window.open(link)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.article-container {
  position: relative;
  min-height: 70vh;
}
</style>
