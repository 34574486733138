<template>
  <article-pages-billet
    v-if="isArticle"
    :toolbar="toolbar"
    :parentCategory="parentCategory"
    :article="article"
    :similar-articles="articles"
  />
  <article-pages-child-category
    v-else
    :toolbar="toolbar"
    :paginated-articles="articles"
    :parent-category="parentCategory"
    :child-category="childCategory"
  />
</template>

<script setup>
import { getImageFromFormatOrDefault, getResponsiveAttributes, replaceBucketByCdn } from '~/helpers/strapi'

const cmsApi = useCmsApi()
const route = useRoute()

const { data: pageArticleOrChildCategoryPayload } = await useAsyncData(() =>
  cmsApi.getPageArticleOrChildCategoryBySlug(
    route.params.parentArticleCategory,
    route.params.articleCategoryOrArticle,
    {
      $preview: !!route.query.preview,
    },
  ),
)

if (!pageArticleOrChildCategoryPayload.value) {
  throw createError({ statusCode: 404, statusMessage: 'Page non trouvée' })
}

const childCategory = pageArticleOrChildCategoryPayload.value.childCategory

let article = null
let articles = null
let pageTitle = ''

if (!pageArticleOrChildCategoryPayload.value.isArticle) {
  articles = pageArticleOrChildCategoryPayload.value.articles
  pageTitle = `${childCategory.title} | Horiz.io`
} else {
  article = pageArticleOrChildCategoryPayload.value.article

  if (!article) {
    throw createError({ statusCode: 404, statusMessage: 'Article non trouvé' })
  }

  articles = pageArticleOrChildCategoryPayload.value.articles

  // Metas
  pageTitle = `${article.title} | Horiz.io`
}

// Data
const isArticle = pageArticleOrChildCategoryPayload.value.isArticle
const parentCategory = pageArticleOrChildCategoryPayload.value.parentCategory
const toolbar = pageArticleOrChildCategoryPayload.value.toolbar

// Head
const config = useRuntimeConfig()
const pageUrl = `${config.public.hpBaseUrl}/${route.params.parentArticleCategory}/${route.params.articleCategoryOrArticle}`
const type = isArticle ? 'article' : 'website'
const title = isArticle ? article.title : childCategory.title
const description = isArticle ? article.seo.metaDescription : childCategory.seo.metaDescription

let featureImageUrl
if (isArticle) {
  featureImageUrl = getImageFromFormatOrDefault(article.cover.image, 'thumbnail')
} else {
  featureImageUrl = getImageFromFormatOrDefault(childCategory.cover.image, 'thumbnail')
}
const featureImageAltText = isArticle ? article.cover.alternativeText : childCategory.cover.alternativeText

const links = [
  {
    rel: 'canonical',
    href: pageUrl,
  },
]

// Improve LCP
if (isArticle) {
  const responsiveAttributes = getResponsiveAttributes(
    [
      { width: '420px', size: 'small' },
      { width: '*', size: 'cover' },
    ],
    article.cover.image,
  )

  links.push({
    rel: 'preload',
    href: replaceBucketByCdn(getImageFromFormatOrDefault(article.cover.image, 'cover')),
    imagesrcset: responsiveAttributes.srcset,
    imagesizes: responsiveAttributes.sizes,
    as: 'image',
  })
}

useHead({
  title: pageTitle,
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: description,
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: title,
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: description,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: featureImageUrl,
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: pageUrl,
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: 'Horiz.io',
    },
    {
      hid: 'og:type',
      property: 'og:type',
      content: type,
    },
    {
      hid: 'twitter:card',
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      hid: 'twitter:image:alt',
      name: 'twitter:image:alt',
      content: featureImageAltText,
    },
  ],
  link: links,
})

const breadcrumb = [
  {
    '@type': 'ListItem',
    position: 1,
    name: 'accueil',
    item: config.public.hpBaseUrl,
  },
  {
    '@type': 'ListItem',
    position: 2,
    name: parentCategory.title,
    item: `${config.public.hpBaseUrl}/${parentCategory.slug}`,
  },
  {
    '@type': 'ListItem',
    position: 3,
    name: childCategory.title,
    item: `${config.public.hpBaseUrl}/${parentCategory.slug}/${childCategory.slug}`,
  },
]

if (isArticle) {
  breadcrumb.push({
    '@type': 'ListItem',
    position: 4,
    name: article.title,
    item: `${config.public.hpBaseUrl}/${parentCategory.slug}/${article.slug}`,
  })
}

const finalObject = isArticle
  ? {
      '@context': 'https://schema.org',
      '@type': 'Article',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': pageUrl,
      },
      headline: article.title,
      image: [getImageFromFormatOrDefault(article.cover.image, 'thumbnail')],
      datePublished: article.publishedAt ? article.publishedAt : article.updatedAt,
      dateModified: article.updatedAt,
      author: {
        '@type': 'Organization',
        name: 'Horiz',
      },
      publisher: {
        '@type': 'Organization',
        name: 'Horiz',
        url: config.public.hpBaseUrl,
        logo: {
          '@type': 'ImageObject',
          url: `${config.public.hpBaseUrl}/imgs/logo.png`,
        },
      },
    }
  : {
      '@context': 'https://schema.org/',
      '@type': 'WebPage',
      name: pageTitle,
      speakable: {
        '@type': 'SpeakableSpecification',
        xpath: ['/html/head/title', "/html/head/meta[@name='description']/@content"],
      },
      url: pageUrl,
    }

useJsonld([
  {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Horiz',
    alternateName: 'Rendement Locatif',
    url: config.public.hpBaseUrl,
    logo: `${config.public.hpBaseUrl}/imgs/logo.png`,
  },
  {
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    url: config.public.hpBaseUrl,
    name: 'Horiz',
    alternateName: 'Rendement locatif',
  },
  {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumb,
  },
  finalObject,
])

onMounted(() => {
  const tracking = useTracking()

  if (isArticle) {
    tracking.trackAfterMounted('[CMS] Article', {
      articleName: article.title,
      articleId: article.id,
    })
  } else {
    tracking.trackAfterMounted('[CMS] Catégorie fille', {
      categoryName: childCategory.title,
      categoryId: childCategory.id,
    })
  }
})
</script>
